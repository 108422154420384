var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex mt-40"},[_c('div',{staticClass:"w50"},[(_vm.prevalence && _vm.prevalence.underweight && _vm.prevalence.underweight.length > 0)?_c('ResultItem',{attrs:{"title":_vm.$t('components.titles.prevalenceOfUnderweight'),"subtext":_vm.$t('components.labels.greaterOrLessZScores', {
        symbol: '<',
        number: '-2'
      }),"prevalence":_vm.prevalence.underweight,"survey-info":_vm.surveyInfo}}):_vm._e(),(!_vm.prevalence || _vm.prevalence.underweight && _vm.prevalence.underweight.length === 0)?_c('a-empty',{attrs:{"description":_vm.$t('components.description.notEnoughDataToDetermineUnderweight')}}):_vm._e(),(_vm.prevalence &&
        _vm.prevalence.moderate_underweight && _vm.prevalence.moderate_underweight.length > 0)?_c('ResultItem',{staticClass:"mt-40",attrs:{"title":_vm.$t('components.titles.prevalenceOfModerateUnderweight'),"subtext":_vm.$t('components.labels.greaterAndLessZScores', {
        symbol: '<',
        number: '-2',
        symbolTwo: '>=',
        numberTwo: '-3'
      }),"prevalence":_vm.prevalence.moderate_underweight,"survey-info":_vm.surveyInfo}}):_vm._e(),(!_vm.prevalence ||
        _vm.prevalence.moderate_underweight && _vm.prevalence.moderate_underweight.length === 0)?_c('a-empty',{staticClass:"mt-40",attrs:{"description":_vm.$t('components.description.notEnoughDataToDetermineModerateUnderweight')}}):_vm._e()],1),_c('div',{staticClass:"w50"},[(_vm.prevalence &&
        _vm.prevalence.severe_underweight && _vm.prevalence.severe_underweight.length > 0)?_c('ResultItem',{attrs:{"title":_vm.$t('components.titles.prevalenceOfSevereUnderweight'),"subtext":_vm.$t('components.labels.greaterOrLessZScores', {
        symbol: '<',
        number: '-3'
      }),"prevalence":_vm.prevalence.severe_underweight,"survey-info":_vm.surveyInfo}}):_vm._e(),(!_vm.prevalence ||
        _vm.prevalence.severe_underweight && _vm.prevalence.severe_underweight.length === 0)?_c('a-empty',{attrs:{"description":_vm.$t('components.description.notEnoughDataToDetermineSevereUnderweight')}}):_vm._e(),(_vm.prevalence && _vm.prevalence.footer)?_c('ResultSummary',{staticClass:"mt-20",attrs:{"summary":_vm.prevalence.footer,"survey-info":_vm.surveyInfo,"type":_vm.type}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }